<template>
<div>
    <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.errorMessage }}
            </CAlert>
        </div>

    <div class="row">
        <div class="col-md-12">
            <CCard>
                <CCardBody>
                    <CForm novalidate>
                        <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                Category
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput
                                    required
                                    was-validated
                                    placeholder="Add category"
                                    v-model="data.name"
                                />
                                <template v-if="!data.name&& buttonClick== true">
                                    <p class="errorMessage">User type required</p>
                                </template>
                            </div>
                        </div>
                    </CForm>

                    <div class="form-group form-actions mr-3 float-right">
                        <router-link to="/master">
                            <CButton type="submit" size="sm" color="danger">
                                Cancel
                            </CButton>
                        </router-link>
                        <CButton
                            type="submit"
                            size="sm"
                            color="primary"
                            class="ml-3"
                            @click="errorCheck()"
                        >
                            Save
                        </CButton>
                    </div>
                </CCardBody>
            </CCard>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
const farmerCategoryUrl = URL + `${"profile/farmer/category/"}`;

export default {
    name: "NewFarmerCategory",

    data() {
        return {
            buttonClick: false,
            errorMessage: "",
            checkError: false,
            data: {
                name: "",
            },
            options: ["Fruits", "Vegetables", "Dry Fruits", "Pulses"],
            horizontal: { label: "col-3", input: "col-9" },
        };
    },
    methods: {
        errorCheck(){
            this.buttonClick= true;

            if(this.data.name.trim().length!= 0){
                    this.buttonClick= false;
                    this.saveCategoryData();
               }
        },

        saveCategoryData() {
            this.axios
                .post(farmerCategoryUrl, this.data, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    this.$router.push("/master");
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                        console.log(error.message);
                    }
                });
        },
    },
    
};
</script>